const DEFAULTS = {
    font: {
        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        size: 12,
        style: 'normal',
        color: '#333333'
    }
}

const COLORS = {
    bruins: {
        all: [39, 116, 174],
        yr: [255, 209, 0],
        mn: [139, 184, 232],
        price: [200, 0, 32],
        alternate: [255, 140, 0]
    },
    huskies: {
        all: [51, 0, 111],
        yr: [232, 211, 162],
        mn: [169, 169, 169],
        price: [200, 0, 32],
        alternate: [255, 140, 0]
    },
    default: {
        all: [0, 34, 68],
        yr: [105, 190, 40],
        mn: [165, 172, 175],
        price: [200, 0, 32],
        alternate: [255, 140, 0]
    }
}

export const getColor = (mode, type) => {
    let colors = null;
    if(mode) {
        colors = COLORS[mode];
    }

    if(!colors) {
        colors = COLORS['default'];
    }

    if(type) {
        return colors[type];
    }
    return colors;
}

export const toColorStr = (color, opacity) => {
    opacity = opacity || 1;
    return `rgba(${color.join(', ')}, ${opacity})`;
}

export const defaultSettings = items => {
    items = items || ['font'];

    const info = {
        flattened: {},
        nested: {}
    };
    items.forEach(element => {
        if(DEFAULTS[element]) {
            info.nested[element] = Object.assign({}, DEFAULTS[element]);
            Object.keys(DEFAULTS[element]).forEach(type => {
                info.flattened[`${element}-${type}`] = info.nested[element][type];
            });
        }
    });

    return info;
}

const all = {
    defaultSettings, getColor, toColorStr
}

export default all