import React from "react"
import Home from '../components/Home';

const homeTemplate = props => {
    const { pageContext } = props
    
    return (
        <Home {...pageContext} />
    )
}
export default homeTemplate;
