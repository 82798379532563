import React from "react";
import PageComp from './PageComp';
import { formatNum } from "../helpers/ScoreHelper";

const Home = props => {
    
    return (
        <PageComp>
            <div style={{fontSize: '100px', textAlign: 'center', paddingTop: '100px'}}><i>(qt)</i><sup>2</sup></div>
            <div style={{textAlign: 'center', 'margin': '50px 0px'}}>
                <a href="/listings">Property Listings ({formatNum(props.listingsCtx.listings.length)})</a> <br/><br/>
                <a href="/estimates">Property Estimates ({formatNum(props.estimatesCtx.estimates.length)})</a>
            </div>
        </PageComp>
    )
}
export default Home;
