import React from "react"
import styled from 'styled-components';
import { defaultSettings } from '../helpers/DefaultSettings';
import logo from '../../static/logo.png'; 
import logoMin from '../../static/logo-min.png'; 

const DEFAULT_SETTINGS = defaultSettings(['font']);

const RootContainer = styled.div`
    font-family: ${DEFAULT_SETTINGS.flattened['font-family']};
    font-size: ${DEFAULT_SETTINGS.flattened['font-size']};
    color: ${DEFAULT_SETTINGS.flattened['font-color']};
    margin-bottom: 150px;

    hr {
        border: 1px solid #cccccc;
        padding: 0;
        margin-top: 0;
    }

    .page-header {
        display: flex;

        .page-header-comp {
            margin-top: 4px;
        }

        a {
            margin: 0
        }

        .page-logo {
            height: 45px;
            margin: 0px 10px 0px 0px;
        }

        .page-logo-min {
            height: 45px;
            margin: 0px 10px 0px 0px;
        }
    
        @media screen and (min-width: 461px) {
            .page-logo-min {
                display: none;
            }
        }
    
        @media screen and (max-width: 460px) {
            .page-logo {
                display: none;
            }
        }
    }
    

    .page-modal {
        padding: 0px !important;
        border-radius: 3px;
        
        .page-modal-title {
            min-height: 50px;
            .close-btn {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        .page-modal-content {
            padding: 10px;

            > div {
                margin-bottom: 20px;
            }
        }

        .page-modal-footer {
            padding: 10px;
        }

        @media screen and (min-width: 601px) {
            min-width: 500px;
        }
        @media screen and (max-width: 600px) {

            width: 99% !important;
        }
    }

    .page-link {
        min-height: 30px;
        min-width: 30px;
        border-radius: 5px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        padding: 0px 10px;
        white-space: nowrap;
        border-width: 1px;
        border-style: solid;
        color: #333333;
    
        &:hover {
          border-color: #1080a2;
        }
    }
`;

const SectionContainer = styled.div`
    max-width: 1440px;
    margin: auto;
    padding: 0px 10px;
`;

const PageComp = props => {

    return (
        <RootContainer>
            <SectionContainer>
                <div className={'page-header'}>
                    <a href={'/'}>
                        <img className={'page-logo'} src={logo} alt="Logo" />
                        <img className={'page-logo-min'} src={logoMin} alt="Logo" />
                    </a>
                    <div className={'page-header-comp'}>
                        {props.headerComp}
                    </div>
                </div>
            </SectionContainer>
            <hr />
            <SectionContainer>
                {props.children}
            </SectionContainer>
        </RootContainer>
    )
}

export default PageComp