import { getColor, toColorStr } from './DefaultSettings'

const SCORE_INFO = [{
    name: 'great',
    label: 'Highly Recommended',
    altLabel: 'top 15%',
    altLabelPre: 'in the',
    min: 85,
    minRating: 4.25,
    recommended: true,
    colorCode: getColor('default', 'yr'),
    color: toColorStr(getColor('default', 'yr'))
}, {
    name: 'good',
    label: 'Recommended',
    altLabel: 'top 30%',
    altLabelPre: 'in the',
    min: 70,
    minRating: 3.5,
    recommended: true,
    colorCode: getColor('bruins', 'all'),
    color: toColorStr(getColor('bruins', 'all'))
}, {
    name: 'ok',
    label: 'Neutral',
    altLabel: 'average',
    altLabelPre: '',
    min: 30,
    minRating: 1.5,
    recommended: false,
    colorCode: getColor('bruins', 'yr'),
    color: toColorStr(getColor('bruins', 'yr'))
}, {
    name: 'bad',
    label: 'Not Recommended',
    altLabel: 'bottom 30%',
    altLabelPre: 'in the',
    min: 0,
    minRating: 0,
    recommended: false,
    colorCode: getColor('default', 'price'),
    color: toColorStr(getColor('default', 'price'))
}]

export const getColorByScore = (score, isRating) => {
    const info = SCORE_INFO.find(item => isRating === true? score >= item.minRating : score >= item.min);
    return info? info.color : SCORE_INFO[SCORE_INFO.length-1].color;
}

export const getInfoByScore = (score, isRating) => {
    let info = SCORE_INFO.find(item => isRating? score >= item.minRating : score >= item.min);
    if(!info) {
        info = SCORE_INFO[SCORE_INFO.length-1];
    }
    info = Object.assign({}, info);
    info.score = isRating? score : Math.round(score*50/100)/10;
    return info;
}

export const getRecommendedInfo = () => {
    const recommended = [];
    SCORE_INFO.forEach(item => {
        if(item.recommended) {
            recommended.push({name: item.name, label: item.label, score: item.min})
        }
    });
    return recommended;
}

export const getInfoByScoreIndex = idx => {
    if(typeof idx === 'undefined' || idx > SCORE_INFO.length - 1 || idx < 0) {
        idx = SCORE_INFO.length - 1
    } 
    return Object.assign({}, SCORE_INFO[idx]);
}

export const formatNum = num => {
    return new Intl.NumberFormat('en-US').format(num)
}